<template>
  <div class="h-full mt-2">
      <div :style="`min-height:` +  cardHeight + `px;`" class="">
        <div class="lg:grid grid-cols-12 gap-4">
          <div class="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6">
            <div class="bg-white rounded cust_card p-2">
              <div class="bg-primary text-white cust_card rounded  p-2 flex items-center justify-between">
                <div class="font-semibold heading-2">
                  Android
                </div>
                <div class=" text-primary heading-6 font-semibold cursor-pointer bg-white px-3 py-1 rounded uppercase" @click="updateRedirect('android')">
                  <i class="fas fa-pen-to-square"></i>
                </div>
              </div>
              <div class="p-2">
                <div class="p-1">
                  <p class="mb-0 items-center flex">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Current Version</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 font-semibold heading-5" v-if="andoirdDetail.currentBuildVersion !== ''">{{andoirdDetail.currentBuildVersion}}</span>
                    <span class="text-text1 heading-5" v-else>---</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0 items-center flex">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Min Support Version</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 font-semibold heading-5" v-if="andoirdDetail.minBuildVersion !== ''">{{andoirdDetail.minBuildVersion}}</span>
                    <span class="text-text1 heading-5" v-else>---</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0 items-center flex">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Release Date</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 font-semibold heading-5" v-if="andoirdDetail.releasedOn !== ''">{{andoirdDetail.releasedOn | dateFilters}}</span>
                    <span class="text-text1 heading-5" v-else>---</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0 ">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Release Note:</p></span>
                    <span class="text-text1 font-semibold heading-5" v-if="andoirdDetail.releaseNote !== ''" v-html="andoirdDetail.releaseNote"></span>
                    <span class="text-text1 heading-5" v-else>---</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6">
            <div class="bg-white rounded cust_card p-2">
              <div class="bg-primary text-white cust_card rounded  p-2 flex items-center justify-between">
                <div class="font-semibold heading-2">
                  iOS
                </div>
                <div class=" text-primary heading-6 font-semibold cursor-pointer bg-white px-3 py-1 rounded uppercase" @click="updateRedirect('ios')">
                  <i class="fas fa-pen-to-square"></i>
                </div>
              </div>
              <div class="p-2">
                <div class="p-1">
                  <p class="mb-0 items-center flex">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Current Version</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 font-semibold heading-5" v-if="iosDetail.currentBuildVersion !== ''">{{iosDetail.currentBuildVersion}}</span>
                    <span class="text-text1 heading-5" v-else>---</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0 items-center flex">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Min Support Version</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 font-semibold heading-5" v-if="iosDetail.minBuildVersion !== ''">{{iosDetail.minBuildVersion}}</span>
                    <span class="text-text1 heading-5" v-else>---</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0 items-center flex">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Release Date</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 font-semibold heading-5" v-if="iosDetail.releasedOn !== ''">{{iosDetail.releasedOn | dateFilters}}</span>
                    <span class="text-text1 heading-5" v-else>---</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0 ">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Release Note:</p></span>
                    <span class="text-text1 font-semibold heading-5" v-if="iosDetail.releaseNote !== ''" v-html="iosDetail.releaseNote"></span>
                    <span class="text-text1 heading-5" v-else>---</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="editDialog" class="popup_overlay px-4">
          <div class="custom_dialog rounded-lg pb-4" style="width: 800px;max-height: 95%;">
            <div class="relative bg-white">
              <div>
                <div class="bg-primary flex justify-between items-center p-3">
                  <div class=" font-medium text-white heading-3">
                    Update Setting: {{selectedVal}} 
                  </div>
                  <div>
                    <span class=" text-text1 heading-6 font-semibold cursor-pointer bg-gray1 px-3 py-2 rounded-lg uppercase" @click="editDialog = false">
                      Cancel
                    </span>
                    <span class=" text-white heading-6 ml-2 font-semibold cursor-pointer bg-success px-3 py-2 rounded-lg uppercase" @click="saveUpdate()">
                      Save
                    </span>
                  </div>
                </div>
                <div class="flex p-2 m-2 text-error bg-red-100 rounded-lg  items-center" role="alert">
                  <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                  <div>
                    <p class=" font-medium heading-6">Please be cautious to change the values, in case of incorrect values the app on the store may break.</p>
                  </div>
                </div>
                <div>
                  <div class=" grid grid-cols-12">
                    <div class="box col-span-6 p-2">
                      <p class="label_css">Current Version</p>
                      <input
                      :class="errObj.currErr !== '' ? 'border border-error' :'border border-gray-500 focus:border-gray-800'"
                      class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                      placeholder="Current Version"
                      v-model="currVersion" @keypress="errObj.currErr=''"
                      >
                      <p class="text-error" v-if="errObj.currErr !== ''">{{errObj.currErr}}</p>
                    </div>
                    <div class="box col-span-6 p-2">
                      <p class="label_css">Min Support Version</p>
                      <input
                      :class="errObj.minSupErr !== '' ? 'border border-error' :'border border-gray-500 focus:border-gray-800'"
                      class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                      placeholder="Min Support Version"
                      v-model="minSupVerson" @keypress="errObj.minSupErr=''"
                      >
                      <p class="text-error" v-if="errObj.minSupErr !== ''">{{errObj.minSupErr}}</p>
                    </div>
                  </div>
                  <div class="p-2">
                    <span class="heading-5 text-text1">Release Note:</span>
                    <Editor v-model="releaseNote" />
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </template>
  <script>
  import Editor from '@/View/components/editor.vue'
  import ADMINAPI from '@/View/Admin/api/Admin.js'
  export default {
    components: {
      Editor
    },
    data () {
      return {
        errObj: {
          currErr: '',
          minSupErr: '',
        },
        currVersion:'',
        minSupVerson: '',
        releaseNote: '',
        cardHeight: 0,
        selectedVal: '',
        andoirdDetail: [],
        iosDetail: [],
        editDialog: false,
      }
    },
      created () {
      window.addEventListener('resize', this.resizeWindowHandler())
    },
    mounted () {
      document.title = 'App Setting'
      console.log('moduleSlugName', this.moduleSlugName)
      this.getAndoirdDetail()
      this.getIosDetail()
    },
    methods: {
      updateRedirect (from) {
        this.selectedVal = from
        this.editDialog = true
        if (from === 'android') {
          this.currVersion = this.andoirdDetail.currentBuildVersion
          this.minSupVerson = this.andoirdDetail.minBuildVersion
          this.releaseNote = this.andoirdDetail.releaseNote
        } else {
          this.currVersion = this.iosDetail.currentBuildVersion
          this.minSupVerson = this.iosDetail.minBuildVersion
          this.releaseNote = this.iosDetail.releaseNote
        }
      },
      saveUpdate () {
        let isErr = false
        console.log('asjhsbagdsagdsa dsa', this.currVersion)
        console.log('asjhsbagdsagdsa dsa', this.minSupVerson)
        if (this.currVersion === '') {
          this.errObj.currErr = 'Current Version is Required'
          isErr = true
        }
        if (this.minSupVerson === '') {
          this.errObj.minSupErr = 'Min Support Version is Required'
          isErr = true
        }
        if (this.minSupVerson > this.currVersion) {
          this.errObj.minSupErr = 'Minimum Version should be less than current version'
          isErr = true
        }
        if (!isErr) {
          let obj = {
            appSettingId: 0,
            minBuildVersion: parseInt(this.minSupVerson),
            currentBuildVersion: parseInt(this.currVersion),
            releaseNote: this.releaseNote,
            releasedOn: '',
          }
          if (this.selectedVal === 'android') {
            obj.appSettingId = this.andoirdDetail.appSettingId
          } else {
            obj.appSettingId = this.iosDetail.appSettingId
          }
          if (this.selectedVal === 'android') {
            obj.releasedOn = this.andoirdDetail.releasedOn
          } else {
            obj.releasedOn = this.iosDetail.releasedOn
          }
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading.....'})
          ADMINAPI.UpdateAppSettingList(
            obj,
            response => {
              this.editDialog = false
              if (this.selectedVal === 'android') {
                this.getAndoirdDetail()
              } else {
                this.getIosDetail()
              }
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            },
            error => {
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            }
          )
        }
      },
      getAndoirdDetail () {
        this.$store.dispatch('SetLoader', {status: false, message: 'Loading.....'})
        ADMINAPI.getAppSettingList(
          'android',
          response => {
            this.andoirdDetail = response.Data !== null ? response.Data : []
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      },
      getIosDetail () {
        this.$store.dispatch('SetLoader', {status: false, message: 'Loading.....'})
        ADMINAPI.getAppSettingList(
          'ios',
          response => {
            this.iosDetail = response.Data !== null ? response.Data : []
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      },
       resizeWindowHandler() {
        this.cardHeight = window.innerHeight - 158
      }
    }
  }
  </script>
  <style>
  .newFont {
    font-family: 'SF UI Text', sans-serif !important;
  }
  .dotwidth {
    width: 15px;
  }
</style>
  